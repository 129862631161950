<script>
import { mapState, mapActions } from "vuex";
import MainButton from "@/components/helpers/MainButton.vue";
import OrganizationCard from "@/components/OrganizationCard.vue";
import MainModal from "@/components/helpers/MainModal.vue";
import SalesPlanDeleteOrganization from "@/components/sales-plan/SalesPlanDeleteOrganization.vue";
import MainNotice from "@/components/helpers/MainNotice.vue";

export default {
  components: {
    MainButton,
    OrganizationCard,
    MainModal,
    SalesPlanDeleteOrganization,
    MainNotice,
  },

  data() {
    return {
      isVisibleDeleteOrganizationModal: false,

      deletedOrganization: null,
      addedOrganization: null,

      isShowNotice: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      bookedCompanies: (state) => state.tsp.bookedCompanies,
    }),

    isPossibilityAddOrganization() {
      return this.bookedCompanies.length < 10;
    },

    addOrganizationNotice() {
      return !this.isPossibilityAddOrganization
        ? "Вы не можете больше добавлять организации"
        : `Вы можете добавить еще ${
            10 - this.bookedCompanies.length
          } предприятий`;
    },

    isShowExam() {
      return (
        this.user.latest_exam === null ||
        this.user.latest_exam?.status !== "passed"
      );
    },
  },

  methods: {
    ...mapActions({
      loadBookedCompanies: "tsp/loadBookedCompanies",
      deleteBookedCompany: "tsp/deleteBookedCompany",
    }),

    showDeleteOrganizationModal(organization) {
      this.deletedOrganization = organization;

      this.isVisibleDeleteOrganizationModal = true;
    },

    deleteOrganizationRequest(company) {
      this.deleteBookedCompany({ id: company.id }).then(() => {
        this.isVisibleDeleteOrganizationModal = false;

        this.loadBookedCompanies();
      });
    },
  },

  mounted() {
    this.loadBookedCompanies();

    if (this.$route.params?.company) {
      this.addedOrganization = this.$route.params.company;
      this.isShowNotice = true;
    }
  },
};
</script>

<template>
  <div class="sales-plan default-content-padding">
    <p class="sales-plan__subtitle">
      План продаж позволяет удобно организовать и закрепить за вами организации,
      с которыми вы ведете переговоры. До истечения срока никто кроме вас не
      сможет взять организацию в работу. Вы можете добавить до 10 организаций.
    </p>

    <template v-if="isShowExam">
      <div class="sales-plan__exam exam">
        <p class="exam__text">
          Чтобы заключать договоры с ТСП, необходимо сдать экзамен
        </p>

        <main-button
          class="exam__button"
          :title="'Записаться на экзамен'"
          color="gold"
          @click="$router.push({ name: 'Study' })"
        />
      </div>
    </template>

    <template v-else>
      <div class="sales-plan__add-organization add-organization">
        <main-button
          v-if="isPossibilityAddOrganization"
          class="add-organization__button"
          :title="'Добавить организацию в план'"
          :color="'outlined'"
          @click="$router.push({ name: 'AddOrganization' })"
        />

        <p class="add-organiztion__notice">
          {{ addOrganizationNotice }}
        </p>
      </div>

      <div class="sales-plan__organizations-list">
        <organization-card
          v-for="company in bookedCompanies"
          :key="company.id"
          :organization="company"
          @delete="showDeleteOrganizationModal"
        />
      </div>
    </template>

    <main-modal
      :show-modal="isVisibleDeleteOrganizationModal"
      :show-icon-close="false"
    >
      <sales-plan-delete-organization
        :organization="deletedOrganization"
        @delete="deleteOrganizationRequest"
        @close="isVisibleDeleteOrganizationModal = false"
      />
    </main-modal>

    <main-notice
      v-if="isShowNotice"
      :notice-type="'success'"
      :title="`${addedOrganization.name} добавлена в ваш план продаж`"
    />
  </div>
</template>

<style lang="scss" scoped>
.sales-plan {
  padding-bottom: $space-xxl;

  &__subtitle {
    margin-top: -$space-xxl;
    @include text-2;
    color: $dark-primary;
    margin-bottom: $space-xxl;
    max-width: 500px;
  }

  .exam {
    &__text {
      @include body-1;
      color: $dark-primary;
      margin-bottom: $space-xl;
      max-width: 416px;
    }

    &__button {
      max-width: 300px;
    }
  }

  .add-organization {
    margin-bottom: $space-xl;

    &__button {
      margin-bottom: $space-xxl;
      max-width: 334px;
    }

    &__notice {
      @include text-2;
      color: $dark-primary;
    }
  }

  &__organizations-list {
    max-width: 644px;
  }
}

@media (max-width: 900px) {
  .sales-plan {
    &__subtitle {
      margin-top: -$space-m;
    }
  }
}
</style>
